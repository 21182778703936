@import '../../style/themes/default';
@import '../../style/mixins/index';

@result-prefix-cls: ~'@{ant-prefix}-result';

.@{result-prefix-cls} {
  padding: 48px 32px;
  // status color
  &-success &-icon > .anticon {
    color: @success-color;
  }

  &-error &-icon > .anticon {
    color: @error-color;
  }

  &-info &-icon > .anticon {
    color: @info-color;
  }

  &-warning &-icon > .anticon {
    color: @warning-color;
  }

  // Exception Status image
  &-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }

  &-icon {
    margin-bottom: 24px;
    text-align: center;

    > .anticon {
      font-size: 72px;
    }
  }

  &-title {
    color: @heading-color;
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }

  &-subtitle {
    color: @text-color-secondary;
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }

  &-extra {
    margin-top: 32px;
    text-align: center;
    > * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: @background-color-light;
  }
}

@menu-dark-item-active-bg: #fff;@menu-dark-selected-item-text-color: #fff;@blue-base: #fff;